import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
// import Parceiro from './components/parceiro/Parceiro'
import Services from './components/services/Services'
import Projetos from './components/projetos/Projetos'
// import Depoimentos from './components/depoimentos/depoimentos'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId:'AW-11080888400'
}
TagManager.initialize(tagManagerArgs);

const App = () => {
    console.group('Site desenvolvido por SAMOEL LAUREANO ANGÉLICA');
    console.groupCollapsed('Contatos')
    console.log('GitHub - https://github.com/samoellaureano');
    console.log('Linkedin - https://www.linkedin.com/in/samoel-laureano-ang%C3%A9lica-208164b1/');
    console.log('Whatsapp - https://api.whatsapp.com/send?phone=5547997386549&text=Olá');
    console.groupEnd();
    console.groupEnd();
    return (
        <>
            <Header />
            <Nav />
            <About/>
            <Services/>
            <Projetos />
            {/* <Parceiro />
            <Depoimentos /> */}
            <Contact />
            <Footer />
        </>
    )
}
export default App