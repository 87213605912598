import React from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { FiInstagram } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const msg = document.querySelector('#msg');

        emailjs.sendForm('service_wjndaz7', 'template_jrq6bon', form.current, 'DrM15vOrTuAUuCfoz').then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
            msg.innerHTML = 'Mensagem enviada com sucesso!';
            msg.setAttribute('class','msg success');
        }, function (error) {
            console.log('FAILED...', error);
            msg.innerHTML = 'Erro ao enviar mensagem!';
            msg.setAttribute('class','msg error');
        });

        e.target.reset();
        //time out para ocultar a mensagem de sucesso
        setTimeout(function () {
            msg.setAttribute('class','msg');
            msg.innerHTML = "";
        }, 5000);
    };




    return (
        <section id="contact">
            <h5>Queremos fazer parte deste sonho</h5>
            <h2>Contate-nos</h2>

            <div className="container contact__container">                
                <div className="contact__options">
                    <a href="https://api.whatsapp.com/send?phone=5547991442090&text=Olá" target="_blank">
                        <article className="contact__option">
                            <BsWhatsapp className="contact__option-icon" />
                            <h4>Whatsapp</h4>
                            <h5>Enviar Mensagem</h5>
                            
                        </article>
                    </a>               
                    <a href="https://www.instagram.com/thiagomachn/" target="_blank">
                        <article className="contact__option">
                            <FiInstagram className="contact__option-icon" />
                            <h4>Instagram</h4>
                            <h5>Conheça nossa rede</h5>
                        </article>
                    </a>
                    <a href="mailto:contato@machndesign.com.br" target="_blank">
                        <article className="contact__option">
                            <MdOutlineEmail className="contact__option-icon" />
                            <h4>Email</h4>
                            <h5>contato@machndesign.com.br</h5>
                            <h6>Enviar Mensagem</h6>
                        </article>
                    </a>
                </div>

                {/* END OF CONTACT OPTIONS */}
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="name" placeholder="Seu nome completo" required />
                    <input type="number" data-mask="(00) 0000-0000" name="whatsapp" placeholder="Seu whatsapp" required />
                    <textarea name="message" rows="7" placeholder="Sua Mensagem" required />
                    <button type="submit" className="btn btn-primary">Enviar Mensagem</button>
                    <div>
                        <span className="msg" id="msg"></span>
                    </div>
                </form>

            </div>
        </section>
    )
}

export default Contact;