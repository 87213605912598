import React from "react";
import "./nav.css";
import {AiOutlineHome} from "react-icons/ai";
import {CgSize} from "react-icons/cg";
import {MdWorkOutline} from "react-icons/md";
import {BiMessageSquareDetail} from "react-icons/bi";
import { useState } from "react";

const Nav = () => {
    const [activeNav, setActiveNav] = useState('#')
    return (
        <nav>
            <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><AiOutlineHome /></a>
            <a href="#services"onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}><CgSize /></a>
            <a href="#projetos"onClick={() => setActiveNav('#projetos')} className={activeNav === '#projetos' ? 'active' : ''}><MdWorkOutline /></a> 
            <a href="#contact"onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><BiMessageSquareDetail /></a>
        </nav>
    )
}

export default Nav;