import React from "react";
import "./header.css";
import HeaderSocials from "./HeaderSocials";
import { BsWhatsapp } from "react-icons/bs";

const Header = () => {
    return (
        <header>
            <div className="container header__container">
                <h4>Seja bem vindo à</h4>
                <h1>MACHN DESIGN</h1>
                <HeaderSocials />

                <div className="imgHeader"></div>

                <a href="#contact" className="scroll__down">@thiagomachn</a>
            </div>
            <a className="conteinerWhats" href="https://api.whatsapp.com/send?phone=5547991442090&text=Olá" target="_blank">
                <BsWhatsapp />
            </a>
        </header>
    )
}

export default Header;