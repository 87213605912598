import React from "react";
import "./footer.css";
import { FiInstagram } from "react-icons/fi";

const Footer = () => {
    return (
        <footer>
            <a href="#" className="footer__logo"> MACHN DESIGN</a>

            <ul className="permalinks">
                <li><a href="#">Home</a></li>
                <li><a href="#about">História</a></li>
                <li><a href="#services">Serviços</a></li>
                <li><a href="#projetos">Projetos</a></li>
                {/* <li><a href="#parceiro">Parceiro</a></li>
                <li><a href="#depoimentos">Clientes</a></li> */}
                <li><a href="#contact">Contatos</a></li> 
            </ul>

            <div className="footer__socials">
                <a href="https://www.instagram.com/studiomachn/" target="_blank"><FiInstagram /></a>
            </div>

            <div className="footer__copyright">
                <small>&copy; MACHN DESIGN. Todos os direitos reservados</small>
            </div>
        </footer>
    )
}

export default Footer;