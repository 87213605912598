import React from "react";
import { FiInstagram } from "react-icons/fi";

const HeaderSocials = () => {
    return (
        <div className="header__socials">
            <a href="https://www.instagram.com/thiagomachn/" target="_blank"><FiInstagram /></a>
        </div>
    )
}

export default HeaderSocials;