import React from "react";
import "./services.css";
import { SiDatabricks } from "react-icons/si";
import { GoLightBulb } from "react-icons/go";
import { MdEngineering } from "react-icons/md";
import { BiBuildingHouse} from "react-icons/bi";


const Services = () => {
    return (
        <section id="services">
            <h5>Conheça nossos</h5>
            <h2>Serviços</h2>

            <div className="headServices">
                <div className="secHeader">
                    <h2>Projeto de Interiores</h2>
                    <h5>Predial - Residencial - Corporativo</h5>
                </div>
                <div className="introServices">
                    <p><i><SiDatabricks /></i>
                        Projeto de Modificação de Planta
                        <h6>Apartamentos em fase de construção</h6></p>
                    <p><i><BiBuildingHouse /></i>
                        Projeto de Interiores,
                        Residencial e Comercial</p>
                    <p><i><GoLightBulb /></i>
                        Assessoria e acompanhamento em lojas</p>
                    <p><i><MdEngineering /></i>
                        Gerenciamento de obra</p>
                </div>
                <div className="contentServices">
                    <h4>Um Projeto de Interiores, consiste na técnica cenográfica e visual para a composição e decoração
                        de ambientes internos. Consiste na arte de planejar e organizar espaços, escolhendo e/ou
                        combinando os diversos elementos de um ambiente, estabelecendo relações estéticas e
                        funcionais, em relação ao que se pretende produzir.</h4>
                </div>
            </div>

            <h2>O projeto é composto pelas seguintes etapas</h2>
            <div className="container services__container">
                <article className="service">
                    <div className="service__head">
                        <h3>Briefing e levantamento métrico</h3>
                    </div>

                    <ul className="service__list">
                        <li>
                            <p>Vamos até seu imóvel, medimos, fotografamos e fazemos uma
                                entrevista com você para conhecer seus hábitos, preferências e necessidades. Nessa etapa, você ganha
                                também uma consultoria de estilo, para que possamos descobrir o seu gosto na arquitetura e o que você
                                quer para o seu lar.
                            </p>
                        </li>
                    </ul>
                </article>

                <article className="service">
                    <div className="service__head">
                        <h3>Estudo preliminar</h3>
                    </div>

                    <ul className="service__list">
                        <li>
                            <p>Etapa onde preparamos uma proposta preliminar com sugestão de layout (posição
                                dos móveis), principais revestimentos, paleta de cores e a atmosfera, o “mood” do seu projeto.
                            </p>
                        </li>
                    </ul>
                </article>

                <article className="service">
                    <div className="service__head">
                        <h3>Projeto executivo</h3>
                    </div>

                    <ul className="service__list">
                        <li>
                            <p> Aprovada a proposta do estudo preliminar, partimos para o detalhamento,
                                definindo detalhes técnicos como projeto luminotécnico, desenhos técnicos de marcenaria, especificação
                                dos móveis e revestimentos, etc.
                            </p>
                        </li>
                    </ul>
                </article>

                <article className="service">
                    <div className="service__head">
                        <h3>Gerenciamento de Obra</h3>
                    </div>

                    <ul className="service__list">
                        <li>
                            <p>Se contratada, nessa etapa fazemos diversos orçamentos com fornecedores
                                de todos os itens do projeto e apresentamos ao cliente para aprovação. Após definirmos a cartela de
                                fornecedores que irão executar a obra, gerenciamos e acompanhamos todo o processo de instalação
                                (iluminação, marcenaria, marmoria...) de forma garantir que a obra está seguindo as premissas
                                estabelecidas no projeto. Para mais, o gerenciamento tem como objetivo central proporcionar aos
                                clientes uma boa experiência com a obra, tendo sempre um profissional lhe auxiliando, evitando dores
                                de cabeça durante a execução das etapas.
                            </p>
                        </li>
                    </ul>
                </article>
            </div>
        </section>
    )
}

export default Services;