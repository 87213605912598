import React from 'react'
import './/about.css'
import IMGAbout from "../../assets/imgNossaHistoria-min.jpg";

const About = () => {
    return (
        <section id='about'>
            <h5>Conheça nossa</h5>
            <h2>História</h2>

            <div className="container about__container">
                <div className="imgAbout">
                    <img src={IMGAbout} alt="" />
                </div>
                <div>
                    <p>A Machn Design, nasceu em 2019 na cidade de Joinville, Santa Catarina. Fundado pelo Arquiteto & Urbanista e Presidente da Marca, Thiago Machn, diplomado pela Universidade Pública do Estado de Santa Catarina / UDESC. O escritório tornou-se referência no estado, quando o assunto é INTERIORES.
                    </p>
                    <p>Atualmente o stúdio conta com uma equipe de seis profissionais, buscando trazer as melhores soluções e inovações para os projetos de seus clientes.
                    </p>
                    <p>Acreditamos fielmente que não trata-se apenas de projetos, mas de sonhos. As pessoas entregam em nossas mãos o seu bem mais preciso – Seu lar! Sendo assim, compreendemos tamanha responsabilidade que temos e a missão que precisamos cumprir, que é a de impactar a vida das pessoas através da arquitetura e do design e promover um espaço para que elas se sintam bem por longos anos de suas vidas.
                    </p>
                    <p>- Thiago Machn
                    </p>
                </div>
            </div>
        </section>
    )
}
export default About