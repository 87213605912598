import React from "react";
import { ImageViewer } from "react-image-viewer-dv";
import IMG1 from "../../assets/COZINHA/cozinha 01-min.png";
import IMG2 from "../../assets/COZINHA/cozinha 02-min.png";
import IMG3 from "../../assets/COZINHA/cozinha 03-min.png";
import IMG4 from "../../assets/COZINHA/cozinha 04-min.png";
import IMG5 from "../../assets/COZINHA/cozinha 05-min.png";
import IMG6 from "../../assets/COZINHA/cozinha 06-min.png";
import IMG10 from "../../assets/QUARTO ANA LIZ/QUARTO ANA LIZ 01-min.png";
import IMG11 from "../../assets/QUARTO ANA LIZ/QUARTO ANA LIZ 02-min.png";
import IMG12 from "../../assets/QUARTO ANA LIZ/QUARTO ANA LIZ 03-min.png";
import IMG13 from "../../assets/QUARTO ANA LIZ/QUARTO ANA LIZ 04-min.png";
import IMG7 from "../../assets/QUARTO CASAL/QUARTO 01-min.png";
import IMG8 from "../../assets/QUARTO CASAL/QUARTO 02-min.png";
import IMG9 from "../../assets/QUARTO CASAL/QUARTO 03-min.png";
import IMG14 from "../../assets/QUARTO MENINO/quarto menino 01-min.png";
import IMG15 from "../../assets/QUARTO MENINO/quarto menino 02-min.png";
import IMG16 from "../../assets/QUARTO MENINO/quarto menino 03-min.png";
import IMG17 from "../../assets/QUARTO MENINO/quarto menino 04-min.png";
import "./projetos.css";

const data = [
    {
        id: 1,
        image: IMG1
    },
    {
        id: 2,
        image: IMG2
    },
    {
        id: 3,
        image: IMG3
    },
    {
        id: 4,
        image: IMG4
    },
    {
        id: 5,
        image: IMG5
    },
    {
        id: 6,
        image: IMG6
    },
    {
        id: 7,
        image: IMG7
    },
    {
        id: 8,
        image: IMG8
    },
    {
        id: 9,
        image: IMG9
    },
    {
        id: 10,
        image: IMG10
    },
    {
        id: 11,
        image: IMG11
    },
    {
        id: 12,
        image: IMG12
    },
    {
        id: 13,
        image: IMG13
    },
    {
        id: 14,
        image: IMG14
    },
    {
        id: 15,
        image: IMG15
    },
    {
        id: 16,
        image: IMG16
    },
    {
        id: 17,
        image: IMG17
    }
]

const Projetos = () => {

    const [visible, setVisible] = React.useState(false);

    return (
        <section id="projetos">
            <h5>Conheça nossos</h5>
            <h2>Projetos</h2>

            <div className="container projeto__container">
                {
                    data.map(({ id, image }) => {
                        return (
                            <article key={id} className="projeto__item">
                                <button onClick={() => { setVisible(true); }} className="projetosImg">
                                    <div className="projeto__item-image">
                                        <ImageViewer>
                                            <img src={image} alt="" />
                                        </ImageViewer>
                                    </div>
                                </button>
                            </article>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Projetos;